<template>
  <div class="d-flex align-items-center min-vh-100">
    <CContainer fluid>
      <CRow class="justify-content-center">
        <CCol md="6">
          <CCard class="mx-4 mb-0">
            <CCardBody class="p-4">
              <CForm>
                <h1>Register</h1>
                <p class="text-muted">Create your account</p>
                <CInput
                  placeholder="Username"
                  autocomplete="username"
                  :value.sync="username"
                >
                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput>
                <CInput
                  placeholder="Email"
                  autocomplete="email"
                  prepend="@"
                  :value.sync="mail"
                />
                <CInput
                  placeholder="Password"
                  type="password"
                  autocomplete="new-password"
                  :value.sync="password"
                >
                  <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                </CInput>
                <CInput
                  placeholder="Repeat Password For Verify"
                  type="password"
                  autocomplete="new-password"
                  :value.sync="passwordVerify"
                >
                  <template #prepend-content><CIcon name="cil-lock-unlocked"/></template>
                </CInput>
              </CForm>
            </CCardBody>
            <CCardFooter class="p-4">
              <CRow>
                <CCol col="6">
                  <CButton block color="primary" @click="register">
                    Register
                  </CButton>
                </CCol>
                <CCol col="6">
                  <CButton block color="success" @click="$router.push('/auth/login')">
                    Go to login
                  </CButton>
                </CCol>
                <CCol col="12">
                  <hr>
                  <CLink href="https://htroy.dev">Htroy</CLink> © 2022 All Rights Reserved.
                </CCol>
              </CRow>
            </CCardFooter>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
    <CModal
      title="Register"
      color="primary"
      :show.sync="registerMsgModal"
    >
      {{ registerMsg }}
    </CModal>
  </div>
</template>

<script>
export default {
  name: 'Register',
  data() {
    return {
      username: null,
      mail: null,
      password: null,
      passwordVerify: null,
      registerMsg: null,
      registerMsgModal: false,
    };
  },
  methods: {
    register: function() {
      this.registerMsgModal=true;
      this.$axios.post("auth/register", {
        username: this.username,
        mail: this.mail,
        password: this.password,
        re_password: this.passwordVerify,
      }, {})
        .then((response) => {
          this.registerMsg = response.data.msg;
          if (response.data.code === 200) {
            setTimeout( () => this.$router.push({ path: '/auth/login'}), 3000);
          }
        });
    }
  }
}
</script>
